.blog__container {
  position: relative;
  width: 100%;
  // height: 300px; /* Set a height for the container */
  background-size: cover;
  background-position: center;
  overflow: hidden;
  .blogImage {
    width: 100%;
    height: 100%;
    filter: brightness(20%);
    object-fit: cover;

    /* Adjust the width and spacing as needed */
  }

  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;

    h3 {
      color: antiquewhite;
    }

    p {
      font-size: 20px;
    }
  }
}
