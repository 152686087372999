// @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/******************** REMOVE DEFAULT STYLES ********************/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  transition: ease 300ms all;
}

/*************** Medium Screen size ***************/
@mixin tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

/********** Mobile Screen size **********/
@mixin mobile {
  @media screen and (max-width: 720px) {
    @content;
  }
}

/******************** SCSS GLOBAL VARIABLES ********************/
$grayHue: 240;
$primaryColor: rgba(255, 77, 0, 1);
$secondaryColor: #f88c34;
//  $primaryColorLignt: #2d1631;
$secondaryColorLight: #e7f7fd;
//  $secondaryColorDark:rgba(219, 149, 110, 1);
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorWhiteLight: rgba(255, 255, 255, 0.3);
$colorGray100: hsl(
  $grayHue,
  47%,
  94%
); /*almost white colour lightness is almost 100%*/

$colorGray200: hsla(0, 0%, 100%, 0.3);
$container-width-lg: 76%;
$container-width-md: 90%;

$transition: all 500ms ease; /* make sure all transition have a constant effect*/

::-webkit-scrollbar {
  width: 0.3em;
}

::-webkit-scrollbar-track {
  background: rgba($color: $primaryColor, $alpha: 0.2);
  margin-block: 0.5rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  background: $primaryColor;
  &:hover {
    background: #000;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", "san-serif";
  line-height: 1.6;
  font-size: 0.9rem;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.container {
  width: $container-width-lg;
  max-width: 1920px;
  margin-inline: auto;
}

.link {
  color: $colorWhite;
}

.btn {
  display: flex;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 274px;
  height: 56px;
  background: $primaryColor;
  color: $colorWhite;
  padding: 0.6rem 2.5rem;
  border: 2px solid transparent;
  border-radius: 1rem;
  cursor: pointer;
  transition: $transition;
}

.btn:hover {
  background-color: transparent;
  color: $colorBlack;
  border-color: $primaryColor;
  transform: translateY(-0.5rem);
}

.btn:active {
  transform: translateY(0);
}

.headline {
  background-color: $secondaryColor;
  height: 0.2rem;
  margin: 0.3rem auto 1.3rem;
  width: 20%;
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.f-c {
  display: flex;
  flex-direction: column;
}

hr {
  display: block;
  height: 1px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  margin: 1em 0;
  padding: 0;
}

.edit-btn {
  display: inline-block;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $colorBlack;
  width: fit-content;
  padding: 0.5rem 1.7rem;
  background-color: none;
  border-radius: 0.5rem;
  transition: $transition;
  border: 2px solid $secondaryColor;

  &:hover {
    color: $colorWhite;
    background: $secondaryColor;
    transition: $transition;
    transform: translateY(-0.2rem);
  }

  @include mobile {
    padding: 0.2rem 1rem;
  }
}

.modify {
  display: flex;
  gap: 2rem;
  margin-block: 1rem;
}
.delete-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $colorBlack;
  width: fit-content;
  padding: 0.7rem 1.7rem;
  background-color: none;
  border-radius: 0.5rem;
  transition: $transition;
  border: 2px solid $primaryColor;

  &:hover {
    background: $primaryColor;
    transition: $transition;
    color: $colorWhite;
    transform: translateY(-0.2rem);
  }

  @include mobile {
    padding: 0.2rem 1rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  color: var(--color-black);
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.4rem;
  color: $colorBlack;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 0.95rem;
}

h6 {
  font-size: 0.8rem;
  font-weight: 600;
}

.book__title {
  color: $primaryColor;
}

.innerPage {
  padding: 4rem 0rem 3rem 3rem;
  width: calc(100vw - 16rem);
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
  body {
    font-size: 1rem;
  }

  .container {
    width: var(--container-width-md);
  }

  section {
    padding: 8rem 0;
  }

  section > p {
    width: 72%;
    margin-bottom: 3rem;
  }

  h1 {
    font-size: 2rem;
  }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
  h1 {
    font-size: 2.4rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4 {
    font-size: 1rem;
  }

  .btn {
    padding: 0.6rem 1.5rem;
  }
}

.errorMsg {
  border: none;
  white-space: nowrap;
  color: #dc143c;
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
}

// div[class="jodit-workplace"] {
//   min-height: 200px !important;
// }
.jodit-workplace {
  min-height: 200px !important;
  max-height: 200px !important;
  overflow-y: auto;
}

div[class="jodit-status-bar__item jodit-status-bar__item-right"] {
  a {
    display: none !important;
  }
}
