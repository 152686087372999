@use "./../../../src//index.scss" as var; // import global variable as an alias

.home__container {
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: calc(90vw);
  margin: 0 auto;
  height: calc(100vh);
  padding: 1rem;
  overflow: auto;
}

.loginSuper {
  color: blue;
  text-decoration: underline;
  margin-right: 0.3rem;
  cursor: pointer;
}
.numIcon {
  display: flex;
  align-items: flex-end;
  gap: 0.2rem;

  span {
    font-weight: 500;
    font-size: 1rem;
  }
}
.home__info-container {
  display: flex;
  color: white;
  gap: 3rem;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 16rem - 4rem - 7rem);
  padding: 1rem 7rem 4rem;
  // background: #376ca1;
  margin: 3rem 3rem;
  border-radius: 20px;
  // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.mhome__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  // padding: 1rem;
  margin-bottom: 30rem;
}

.user__container > div {
  width: calc(50% - 10px); /* Adjust the width and spacing as needed */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
}

.user__container {
  padding-bottom: 9rem;
  display: flex;
  flex-direction: column;
}

.name {
  margin-bottom: 10px;
}

.name p {
  margin: 5px 0;
}

.modify {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

h3 {
  margin-top: 0;
}
