@use "./../../index" as var; // import global variable as an alias

.sidebar {
  // position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .logo__container {
    // padding: 1rem;
    padding-top: 1rem;
    margin-left: 2rem;
    margin-bottom: 2rem;

    img {
      width: 5rem;
      height: 3rem;
    }
  }
  .sidebar__menu {
    display: flex;
    justify-content: flex-start;
    margin-left: 2rem;

    .menu__wrap {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 70vh;

      li {
        cursor: pointer;
        transition: var.$transition;
        &:hover {
          transition: var.$transition;
          color: var.$secondaryColor;
        }
      }
      .active-nav {
        color: var.$secondaryColor;
      }
      .black {
        color: var.$colorBlack;
      }
      a {
        transition: var.$transition;

        &:hover {
          transition: var.$transition;
          color: var.$secondaryColor;
          transition: var.$transition;
        }
      }
      .bold {
        font-size: 1rem;
        font-weight: 700;
      }

      .dropdown__title {
        display: flex;
        gap: 0.2rem;
        justify-content: center;
        align-items: center;
        background: none;
      }

      .dropdown-menu {
        padding-top: 0.5rem;
        height: auto;
        transform: translateX(0);
        animation: dropDownOpen 400ms ease;
        font-size: 12px;
        @keyframes dropDownOpen {
          from {
            height: 0%;
            transform: translateX(100px);
            opacity: 0;
          }
        }
        li {
          margin-bottom: 0.2rem;
          // border: 1px solid #000;
          position: relative;
          width: fit-content;
          &:hover {
            &::after {
              width: 50%;
            }
          }
          &::after {
            position: absolute;
            width: 0%;
            height: 1px;
            background-color: var.$primaryColor;
            content: "";
            bottom: -1px;
            left: 0;
          }
        }
        a {
          transition: var.$transition;

          &:hover {
            transition: var.$transition;
          }
        }
      }
    }
  }

  .logout {
    width: 64px;
    height: 15px;
    margin: 1.5rem 1rem;
    margin-left: 2rem;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    color: var.$primaryColor;
    cursor: pointer;
  }
}
