@use "./../../../index.scss" as var; // import global variable as an alias

.boardofdirectors {
  // width: calc(100vw - 16rem);
  // margin: 1rem auto;
  // padding: 2rem 4rem;
  // margin-inline: auto;
  // max-width: 1920px;
  h1 {
    margin-bottom: 1rem;
  }

  h4 {
    line-height: 1.5rem;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1rem;
      background-color: var.$colorWhite;
      top: 3rem;
      left: 0;
      box-shadow: 6px 6px 4px rgba($color: var.$colorBlack, $alpha: 0.05);
    }
  }
}

.add__board {
  color: var.$secondaryColor;
  font-size: 1rem;
  cursor: pointer;
  margin-block: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  &:hover {
    color: var.$colorBlack;
  }
}
.boardofdirectors__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 12px;
  overflow-y: auto;
  height: calc(80vh - 5rem);
  margin-top: 1rem;
  padding: 1rem 0 3.5rem;
  // margin: 2rem 1rem 4rem;
  &-inside {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 16px;
    // border: 1px solid #000;
  }
  .boardofdirectors__image {
    height: fit-content;
    margin-bottom: 1.5rem;
    max-height: 15rem;
    min-height: 7rem;
    overflow: hidden;
    width: 100%;
    height: 274px;
    border-radius: 1rem;

    img {
      display: block;
      object-fit: cover;
      width: 100%;
    }

    p {
      font-size: 12px;
    }
  }
  .modify {
    margin-top: auto;
  }
}
