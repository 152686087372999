@use "./../../../index.scss" as var; // import global variable as an alias
.macn__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: calc(80vw);
  margin: 0 auto;
  height: calc(100vh);
  padding: 1rem;
  overflow: auto;
  margin-bottom: 30rem;
}

.macn__container > div {
  width: calc(50% - 10px); /* Adjust the width and spacing as needed */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
}

.macn__container {
  padding-bottom: 9rem;
}

.created {
  margin-bottom: 10px;
}

.created p {
  margin: 5px 0;
}

.modify {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

h3 {
  margin-top: 0;
}
