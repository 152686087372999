@use "./../../../index.scss" as var; // import global variable as an alias

.visionmission__container {
  // margin: 1rem auto;
  margin-inline: auto;
  max-width: 1920px;
  //   display: flex;
  overflow-y: auto;
  height: calc(100vh - 5rem);
  // margin-block: 3rem;
  // padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  .visionmission__container-vision {
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;
  }

  .visionmission__container-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    .flex-container {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
    }
  }
  .visionmission__container-left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .vision__mission-container {
      margin-bottom: 1.5rem;
      max-height: 20rem;
      min-height: 10rem;
      overflow: hidden;
      width: 329px;
      height: 274px;
      display: inline-flex;
      // border: 1px solid #000;
      border-radius: 12px;
      img {
        display: inline-flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
        scale: 1.1;
        object-position: top;
      }
    }
  }
}
