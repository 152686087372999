$colorBlack: #000000;
$white: #ffffff;

//Font Size
$font-size-102: 102px;
$font-size-80: 80px;
$font-size-70: 70px;
$font-size-60: 60px;
$font-size-50: 50px;
$font-size-40: 40px;
$font-size-35: 35px;
$font-size-30: 30px;
$font-size-24: 24px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-16: 16px;

//Font weight
$fontWeight-200: 200;
$fontWeight-300: 300;
$fontWeight-400: 400;
$fontWeight-500: 500;
$fontWeight-600: 600;
$fontWeight-700: 700;
$fontWeight-800: 800;
$fontWeight-900: 900;

/******************** SCSS GLOBAL VARIABLES ********************/
$grayHue: 240;
$primary-color: #d23a2c;
$secondary-color: #f88c34;
$secondaryColorLight: #e7f7fd;
$colorWhiteLight: rgba(255, 255, 255, 0.3);
$colorGray100: hsl($grayHue, 47%, 94%); /*almost white colour lightness is almost 100%*/

$grey-bg: #F9F5F1;
$dark-grey: #E2E0E0;
$success-color : #00c940;
$failed-color : #DC143C;
$peach : #FF7875;
$standard : #34ABAE;
$premium : #006aff;

$colorGray200: hsla(0, 0%, 100%, 0.3);
$container-width-lg: 76%;
$container-width-md: 90%;

$transition: all 500ms ease; /* make sure all transition have a constant effect*/
