.admin__page-wrapper {
  display: flex;

  .admin__page-left {
    width: 16rem;
    height: 100vh;
    background: #f9f9f9;
  }

  // .admin__page-right {
  //   // height: 90vh;
  // }
}
