@use "./../../index" as var; // import global variable as an alias

.register__container {
  position: relative;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  // padding-top: 64px;
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  padding-bottom: 64px;

  .logo__container {
    img {
      position: absolute;
      left: 42px;
      top: 30px;
      width: 5rem;
      height: 3rem;
    }
  }

  .register__section {
    display: grid;
    // place-content: center;
    // height: 100vh;
    span {
      margin-top: 3px;
      width: 94px;
      height: 2px;
      background: var.$primaryColor;
      margin-bottom: 2rem;
    }
    // display: grid;
    place-items: center;
  }

  .input__container {
    margin-block: 1rem;
    gap: 0.5rem;
  }

  label {
    font-weight: 700;
  }

  input {
    background: #f1f1f1;
    border: 1px solid #c4c4c4;
    width: 500px;
    // height: 50px;
    padding: 12px;
  }

  .register__reset {
    display: flex;
    width: 100%;
    justify-content: space-between;
    p {
      font-weight: 700;
    }
  }

  button {
    margin-top: 4rem;
  }
}
