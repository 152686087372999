@import "./reuseables";

.editBoardOfDirectorPanelContainer {
    .infoArea {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 20px;

        .fieldControl {
            display: flex;
            flex-direction: column;
            gap: 2px;
            max-width: 450px;
            min-width: 450px;

            label {
                font-size: 12px;
            }
            input,
            textarea {
                border: 1px solid rgba($color: $colorBlack, $alpha: 0.2);
                padding: 8px;
                &::placeholder {
                    font-size: $font-size-16 - 4px;
                }
                &:focus {
                    // border: 2px solid #000;
                    box-shadow: 0px 0px 0px 2px rgba($color: $primary-color, $alpha: 0.2);
                }
            }
            textarea {
                min-height: 100px;
            }
            .errorMsg {
                font-size: 12px;
                color: $failed-color;
            }
            .imageControl {
                border: 1px solid rgba($color: $colorBlack, $alpha: 0.2);
                height: 200px;
                max-height: 200px;
                border-radius: 12px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                input {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    opacity: 0;
                }

                .imageChosen {
                    position: relative;
                    width: 100%;
                    height: 200px;
                    max-height: 200px;
                    border-radius: 12px;
                    display: inline-flex;
                    justify-content: flex-start;
                    overflow: hidden;
                    img {
                        border-radius: 12px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
