@import "../../index.scss";

.resources__container {
  overflow-y: auto;
  height: 100vh;
}

.resources__container-files {
  display: grid;
  //   grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 12px;

  .resources__container-file {
    display: grid;
    gap: 1rem;
  }

  h4 {
    color: $colorBlack;
  }

  .files___image {
    height: fit-content;
    margin-bottom: 1.5rem;
    max-height: 15rem;
    min-height: 7rem;
    overflow: hidden;
    width: 100%;
    height: 274px;
    border-radius: 1rem;

    img {
      display: block;
      object-fit: cover;
      width: 100%;
    }
  }

  .flex {
    gap: 2rem;
  }
}
