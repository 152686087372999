.preloaderContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
  z-index: 10; /* Using 100% viewport height for full-screen effect */
}

.loader {
  width: 120px;
  height: 120px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: "";
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #ef7c00;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  animation: animloader 2s ease-in-out infinite;
}

.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0%,
  100% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    transform: scale(0);
    opacity: 1;
  }
}

@media (max-width: 426px) {
  .loader,
  .loader::after,
  .loader::before {
    width: 60px;
    height: 60px;
  }
}
