@import "./variables.scss";

// Animation
@keyframes slideLeft {
    from {
        left: -100%;
        opacity: 0;
    }
}
@keyframes slideRight {
    from {
        right: -100%;
        opacity: 0;
    }
}

@mixin button($color) {
    padding: 8px 12px;
    cursor: pointer;
    background-color: $color; 
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 8px;
}

@mixin topArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    &__lhs {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.leftSidebarContainer {
    width: 100%;
    height: 100vh;
    // height: calc(100vh - 48px);
    position: fixed;
    left: 0;
    bottom: 0;
    // top: 0;
    z-index: 120;
    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.5);
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
    }
    .container {
        background-color: $white;
        width: 30%;
        width: fit-content;
        height: 100%;
        left: 0;
        padding: 24px;
        position: absolute;
        z-index: 4;
        overflow-y: auto;
        button {
            @include button($secondary-color);
        }
        .topArea {
            @include topArea();
            button {
                @include button($primary-color);
            }
        }
    }

    animation: slideLeft 640ms cubic-bezier(0.86, 0, 0.07, 1);
    // animation: slideLeft 640ms cubic-bezier(0.65, -0.07, 0.25, 1.24);
}

.rightSidebarContainer {
    width: 100vw;
    min-width: 100%;
    height: 100vh;
    // height: calc(100vh - 48px);
    position: fixed;
    left: 0;
    bottom: 0;
    // top: 0;
    z-index: 3;
    // border: 10px solid #fff;

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.5);
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
    }
    .container {
        background-color: $white;
        width: 80%;
        width: fit-content;
        height: 100%;
        right: 0;
        position: absolute;
        z-index: 4;
        padding: 24px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        animation: slideRight 640ms cubic-bezier(0.86, 0, 0.07, 1);
        button {
            @include button($secondary-color);
        }
        .topArea {
            @include topArea();
            button {
                @include button($primary-color);
            }
        }
    }
}
